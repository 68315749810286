
import {
  IonContent,
  IonHeader,
  IonPage,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
  menuController,
  IonApp,
  IonIcon,
  IonLabel,
  IonMenuToggle,
  IonFooter,
  IonButton,
  IonButtons,
  IonModal,
  IonItem,
  IonCol, IonBadge, IonRow,IonInput,
} from '@ionic/vue';

import { stop, personCircle, call, person, settings, storefrontOutline, chatbox, pinSharp, cart, giftOutline, fingerPrint, chatboxEllipses, exitOutline, locationOutline, search } from 'ionicons/icons';

import { Drivers, Storage } from '@ionic/storage'

import { Geolocation } from '@capacitor/geolocation'

import { defineComponent } from 'vue';
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import fintailapi from './api'

import { SafeArea } from 'capacitor-plugin-safe-area';

/*
  enderecoProcurar = ''
  searchEnderecos = []

  currentEnderecos = { cep: '', endereco: '', loja: '', latitude: 0, longitude: 0 }
*/

export default defineComponent({
  inheritAttrs: true,
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonList,
    IonMenu,
    IonRouterOutlet,
    IonTitle,
    IonToolbar,
    IonApp,
    IonIcon,
    IonLabel,
    IonMenuToggle,
    IonFooter,
    IonButton,
    IonButtons,
    IonModal,
    IonItem,
    IonCol, IonBadge, IonRow, IonInput
  },
  computed: {
    user(){
      return useStore().state.user.info
    },
    carts() {
      return  useStore().getters.carts
    },
    cartItemNumber() {
      return  useStore().getters.cartItemNumber
    },
    notifications() {
      return  useStore().state.notification.nao_lidas
    },

  },
  methods: {
    navigate(url:any) {
      this.router.push(url);
      menuController.toggle();
    },
    openFirst() {
      menuController.enable(true, 'first');
      menuController.open('first');
    },
    openEnd() {
      menuController.open('end');
    },
    openCustom() {
      menuController.enable(true, 'custom');
      menuController.open('custom');
    }
  },
  data() {
    return {
      isOpenLocation  : false,
      isOpenSemEntrega: false,
      enderecoProcurar: '',
      searchEnderecos : [],
      currentEnderecos: { cep: '', endereco: '', loja: '', latitude: 0, longitude: 0 }
    }
  },
  setup() {

    menuController.swipeGesture(false)

    const router = useRouter()
    const store = useStore()

    const storage = new Storage({
      name: '__jauserve',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    });
    storage.create();

    const currentEnderecos = {}

    return {router , store, storage, call, personCircle,stop, chatbox, person, settings,storefrontOutline, pinSharp, cart, giftOutline, fingerPrint, chatboxEllipses, exitOutline, currentEnderecos, locationOutline, search };
  },
  mounted() {

    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      this.store.state.safeTop = insets.top
    });


    this.$nextTick(function () {
      (async () => {

        const location = await this.storage.get('location')

        if (!location || !location.cep){
          //this.isOpenLocation = true
        }else{

          this.store.state.user.geolocation = location
          fintailapi.defaults.headers.common['Store'] = location.codigo_loja

        }

        //const banners = await this.storage.get('banners')


      })()

    })
  }
});
