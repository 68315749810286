import { IonicVue, alertController } from '@ionic/vue';
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import maska from 'maska'



import * as Sentry from "@sentry/vue";

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { FacebookLogin } from "@capacitor-community/facebook-login";

import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import VueGtag from "vue-gtag";

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import './style/_main.css';

import {
    IonContent,
    IonHeader,
    IonPage,
    IonList,
    IonMenu,
    IonRouterOutlet,
    IonTitle,
    IonToolbar,
    menuController,
    IonApp,
    IonIcon,
    IonLabel,
    IonListHeader,
    IonMenuToggle,
    IonNote,
    IonSplitPane,
    IonFooter
  } from '@ionic/vue';


const app = createApp(App)
    .use(store)
    .use(IonicVue)
    .use(maska)
    .use(router)
    .use(VueGtag, {
      config: { id: "G-E8X139ZW0S" }
    }, router);

    router.isReady().then(() => {
      app.mount('#app');
    });

    FacebookLogin.initialize({appId: process.env.VUE_APP_FACEBOOK});

    Sentry.init({
      app,
      dsn: "https://2333d25f33514a579faffa61253113ee@o4504700931866624.ingest.sentry.io/4504952330715136",
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ["localhost", "app.fintail.com.br", "stage.fintail.com.br","dev.fintail.com.br", /^\//],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });



    let reOpen = localStorage.getItem('reOpen');

    if(reOpen){
      store.dispatch('reOpen', false)
    }else{
      //CARREGA AS CIDADES
      store.dispatch('loadLocationData', false)
    }

    const timeoutInMS = 25 * 60 * 1000; // 3 minutes -> 3 * 60 * 1000
    let timeoutId=0;

    function handleInactive() {
      store.dispatch('ping', false)
      startTimer();

    }

    function startTimer() {
        // setTimeout returns an ID (can be used to start or clear a timer)
        timeoutId = setTimeout(handleInactive, timeoutInMS);
    }

    function resetTimer() {
        clearTimeout(timeoutId);
        startTimer();
    }

    function setupTimers () {

        /*document.addEventListener("keypress", resetTimer, false);
        document.addEventListener("mousemove", resetTimer, false);
        document.addEventListener("mousedown", resetTimer, false);
        document.addEventListener("touchmove", resetTimer, false);*/

        startTimer();
    }

    setupTimers ()

    app.config.globalProperties.$cart = {

      convidado(){
        const alert = alertController.create({
          header: 'Convidado',
          subHeader: '',
          message: 'Para usar essa função você necessita estar logado',
          buttons: [
            {
                text: 'Voltar',
                role: 'cancel',
                handler: () => {

                }
            },
            {
                text: 'Login',
                handler: (alertData) => { //takes the data
                  router.push("/prelogin")
                }
            }
        ],
        }).then(res => {

        res.present();

        });

      },


      currencyUSD(value:any) {

        //if(!value)return "";
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
        return formatter.format(value);

      },
      currencyUP(value:any) {

        if(value == undefined)return[0, 0];

        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });

        const cents = formatter.formatToParts(value);

        return [cents[2].value, cents[4].value] || ['',''];

      },

      doAddCartMain (produto : any) {

        if(store.state.user.isGuest){
          this.convidado()
          return
        }

        if(produto.max_cart_order_quantity){
          if(produto.qtd > produto.max_cart_order_quantity){
            return
          }
        }

        if(produto.qtd >= 1)
          produto.qtd++;
        else
          produto.qtd=1;

        if(produto.qtd > 75){
          produto.qtd=75;
        }

        produto.incart = true;

        store.dispatch('addToCart', produto)

      },

      doAddMainPlus (produto : any) {

        if(!produto.qtd)
          if(produto.quantity){
            produto.qtd=produto.quantity
          }else{
            produto.qtd=0
          }

        if(produto.qtd > 74){
          produto.qtd = 74
        }


        if(produto.max_cart_order_quantity){
          if(produto.qtd >= produto.max_cart_order_quantity){

            produto.qtd = produto.max_cart_order_quantity
            return
          }
        }
  

        if (typeof produto.qtd === 'string') {

          produto.qtd = parseFloat(produto.qtd);

        }
      
        if(!produto.changed)produto.qtd++;

        store.dispatch('addPlusCart', produto)

      },

      doAddMainMinus (produto : any) {


        if(produto.qtd > 74){
          produto.qtd = 74
        }


        if(produto.qtd > 0){
          produto.qtd--;
        }

        if( produto.qtd == 0 ){

          produto.incart = false;
          //$scope.removeItemById(produto.id);
        }else{
          //$scope.addItem(produto.id, produto.nome, produto.preco, produto.qtd, produto);
        }


        if(produto.max_cart_order_quantity){
          if(produto.qtd > produto.max_cart_order_quantity){

            produto.qtd = produto.max_cart_order_quantity

          }
        }

        store.dispatch('addMinusCart', produto)

      },
      doAddCartQtd (produto : any, qtd: number) {

        if(produto.qtd > 75){
          produto.qtd=75
        }

        if(produto.qtd >= 1)
          produto.qtd=produto.qtd+qtd;
        else
          produto.qtd=qtd;

        produto.incart = true;

        if(produto.qtd > 75){
          produto.qtd=75
        }

        store.dispatch('addToCartQTD', produto)

      },
      doSetCartQtd (produto : any, qtd: number) {


        if(store.state.user.isGuest){
          this.convidado()
          return
        }

        if(qtd == undefined){
          produto.qtd = 0
          qtd = 0
        }



        if(qtd == 0){
          qtd++;
          produto.quantity = produto.quantity + qtd;
          produto.qtd = produto.qtd + qtd;
        }

        if(produto.qtd > 75){
          produto.qtd=75
        }

        if(qtd > 0){

          produto.qtd = parseFloat(produto.qtd);


          if(produto.qtd >= 1)
            produto.qtd = produto.qtd;
          else
            produto.qtd=1;


          produto.incart = true;
          store.dispatch('setToCartQTD', produto)

        }
      },
      doPlusCartQtd (produto : any, qtd: number) {


        if(store.state.user.isGuest){
          this.convidado()
          return
        }

        if(qtd == undefined){
          produto.qtd = 0
          qtd = 0
        }




        qtd++;
        produto.quantity = produto.quantity + qtd;
        produto.qtd = produto.qtd + qtd;


        if(produto.qtd > 75){
          produto.qtd=75
        }

        if(qtd > 0){

          produto.qtd = parseFloat(produto.qtd);


          if(produto.qtd >= 1)
            produto.qtd = produto.qtd;
          else
            produto.qtd=1;


          produto.incart = true;
          store.dispatch('setToCartQTD', produto)

        }
      }, doSetCartRemove (produto : any) {


        if(store.state.user.isGuest){
          this.convidado()
          return
        }

        produto.qtd = 0
        produto.quantity = 0
        produto.incart = false;
        store.dispatch('removeFromCart', produto)

      
      },

    }



