import axios from "axios";
import router from './router'



const meuValor = ""

const apis = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API,
  headers: {
    'X-APP-VER' : process.env.VUE_APP_VERSION,
    'X-APP-PACKAGE' : process.env.VUE_APP_PACKAGE,
  }
});

apis.defaults.timeout = 35000;

apis.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {

    //const access_token = localStorage.getItem("access_token");

    if(!error.response)return Promise.reject(error);

    if (error.response.status === 500) {
      //console.log(error);
      //const response = await refreshToken(error);
      //return response;
    }

    if (error.response.status === 401) {

      router.push("/login")
      //const response = await refreshToken(error);
      //return response;
    }

    if (error.response.status === 402) {

      router.push("/mercado")
      //const response = await refreshToken(error);
      //return response;
    } 

    return Promise.reject(error);
  }
);



export default apis
