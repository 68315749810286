import { createStore } from 'vuex'

import fintailapi from '../api'

import Vue from 'vue';
import Vuex from 'vuex';

import user, { User } from './modules/user';
//import locations, { LocationState } from './modules/locations';

export interface StoreState {
  user: User
}

export interface Locations {
  id: number;
  name?: string;
  lat: number;
  lng: number;
}

export default createStore({
  state : {
    user : {
        id: 0,
        info:{},
        userName: "",
        isBemvindo: true,
        isAuthenticated: false,
        isGuest: false,
        geolocation:{entrega:false},
        isFirst:true,
    },
    banner : true,
    notification : { nao_lidas : 0 },
    products: [],
    basket:{ basket_id: 0, product_items:[{id : 0, quantity : 0, price: 0, item_id:0, price_after_item_discount:0 }] },
    carts: [],
    delivery: { isdelivery : false, mode:{}, values:{} },
    locations: []
  },
  mutations: {
    SET_PRODUCT_TO_CART_QTD(state, product) {



      product.loading=true
      const carts = [ ...state.basket.product_items ]
      const index = carts.findIndex(cart => cart.id === product.id)
      let item_id = null
      if (index !== -1) {

        const item = carts[index]
        /*if(product.qtd){
          carts[index].quantity=product.qtd
        }else{
          carts[indx].quantity++
        }*/

        item_id = item.item_id

      }

      product.loading=true
      fintailapi
      .post('/carrinho/update', { "baskets_id": state.basket.basket_id, "item_id":item_id,  "product_id" : product.ean ? product.ean : product.id_jau, "qty" : product.qtd, "image_path" : product.image })
      .then(function (response) {



        let data = response.data
        if(data.success == true){

          let product_items = data.product_items;

          const carts = [ ...product_items ]
          const index = carts.findIndex(cart => cart.id === product.id)

          //const carts = [ ...state.basket.product_items ]
          //const index = carts.findIndex(cart => cart.id === product.id)
         /* if (index !== -1) {
            carts[index].quantity=+product.qtd
          } else {
            carts.push({ ...product, quantity: product.qtd })
          }
*/
          state.basket.product_items = [ ...carts  ]

          product.loading = false

        }else if(data.code==-1){
          product.not_avaliable = true
          product.loading = false
        }else if(data.code==-2){
          product.not_avaliable = true
          product.error = data.err
          product.qtd--;
          product.loading = false

        }

      }, function(response) {

      });

    },
    ADD_PRODUCT_TO_CART_QTD(state, product) {

      product.loading=true
      fintailapi
      .post('/carrinho/add', { "baskets_id": state.basket.basket_id, "product_id" : product.ean ? product.ean : product.id_jau, "qty" : product.qtd, "image_path" : product.image })
      .then(function (response) {

        product.loading = false

        let data = response.data
        if(data.success == true){

          const carts = [ ...state.basket.product_items ]
          const index = carts.findIndex(cart => cart.id === product.id)
          /*if (index !== -1) {
            carts[index].quantity=+product.qtd
          } else {
            carts.push({ ...product, quantity: product.qtd })
          }
*/
          state.basket.product_items = [ ...carts  ]

        }

      }, function(response) {

      });

    },
    ADD_PRODUCT_TO_CART(state, product) {

      product.loading=true

      fintailapi
      .post('/carrinho/add', { "baskets_id": state.basket.basket_id, "product_id" : product.ean ? product.ean : product.id_jau, "qty" : 1, "image_path" : product.image })
      .then(function (response) {

        product.loading = false

        let data = response.data
        if(data.success == true){

          let product_items = data.product_items;

          const carts = [ ...product_items ]
          const index = carts.findIndex(cart => cart.id === product.id)
          /*if (index !== -1) {
            carts[index].quantity++
          } else {
            carts.push({ ...product, quantity: 1 })
          }*/

          state.basket.product_items = [ ...carts  ]

        }else if(data.code==-1){
          product.not_avaliable = true;
          product.loading = false
        }else if(data.code==-2){
          product.not_avaliable = true;
          product.error = data.err
          product.qtd--;
          product.loading = false
        }

      }, function(response) {

        product.qtd--;

      });

    },
    REMOVE_PRODUCT(state, product) {

      const carts = [ ...state.basket.product_items ]
      const index = carts.findIndex(cart => cart.id === product.id)
      if (index !== -1) {
        const item = carts[index]
        if (item.quantity === 0) {
          carts.splice(index, 1)
        } else {
          carts[index].quantity--
        }

        fintailapi
        .post('/carrinho/update', { "baskets_id": state.basket.basket_id, "item_id":item.item_id, "product_id" : product.ean ? product.ean : product.id_jau, "qty" : item.quantity, "image_path" : product.image })
        .then(function (response) {

          let product_items = response.data.product_items;

          const carts = [ ...product_items ]
          state.basket.product_items = [ ...carts  ]

          product.loading=false


        }, function(response) {

        });

      }
      state.basket.product_items = [ ...carts ]

    },
    PLUS_PRODUCT_TO_CART(state, product) {
      product.loading=true
      const carts = [ ...state.basket.product_items ]
      const index = carts.findIndex(cart => cart.id === product.id)

      if (index !== -1) {

        const item = carts[index]
        if(product.qtd){
          carts[index].quantity=product.qtd
        }else{
          carts[index].quantity++
        }

        fintailapi
        .post('/carrinho/update', { "baskets_id": state.basket.basket_id, "item_id":item.item_id, "product_id" : product.ean ? product.ean : product.id_jau, "qty" : item.quantity, "image_path" : product.image })
        .then(function (response) {

          let data = response.data
          if(data.success == true){

            let product_items = data.product_items;
            const carts = [ ...product_items ]
            state.basket.product_items = [ ...carts  ]

          }

          product.loading=false

        }, function(response) {
                product.loading = false
                product.qtd--;
         });
      }

      state.basket.product_items = [ ...carts ]

    },
    MINUS_PRODUCT_TO_CART(state, product) {
      
      product.loading=true
      
      const carts = [ ...state.basket.product_items ]
      const index = carts.findIndex(cart => cart.id === product.id)
      if (index !== -1) {

        const item = carts[index]

        if(product.qtd){
          carts[index].quantity=product.qtd
        }else{
          carts[index].quantity--
        }


        if (item.quantity === 0) {
          carts.splice(index, 1)
        }

        fintailapi
        .post('/carrinho/update', { "baskets_id": state.basket.basket_id, "item_id": item.item_id,  "product_id" : product.ean ? product.ean : product.id_jau, "qty" : item.quantity, "image_path" : product.image })
        .then(function (response) {

          let data = response.data
          if(data.success == true){
            let product_items = response.data.product_items;

            
            
            const carts = [ ...product_items ]
            const product_items_with_removed = carts.map(item_remov => ({ ...item_remov, removed: item_remov.id ==item.id }));

            state.basket.product_items = [...product_items_with_removed];
            //state.basket.product_items = [ ...carts  ]
          }

          product.loading   = false
          

        }, function(response) {
          //product.qtd--;
          //product.loading = false
        });

      }
      state.basket.product_items = [ ...carts ]

    },
    ping(state) {

      fintailapi
      .get('/user/refresh')
      .then(function (response) {

        const data = response.data
        fintailapi.defaults.headers.common['Authorization'] = data.token;


      }, function(response) {

      });
    },
    reOpen(state, userName: string) {
      state.user.isBemvindo = false;
    },
    logIn(state, userName: string) {
      state.user.userName = userName;
      state.user.isAuthenticated = true;
    },
    logOut(state) {
      state.user = {
                      id: 0,
                      info:{},
                      userName: "",
                      isBemvindo: true,
                      isAuthenticated: false,
                      isGuest: false,
                      isFirst:true,
                      geolocation:{entrega:false},
                  };
    },
    updateUserPicture(state, pictureLocation: string) {

    },
    updateLocations(state, locations: any) {
      state.locations = locations;
    },
    updateNotification(state, userName: string) {
      state.user.userName = userName;
      state.user.isAuthenticated = true;
    },
  },
  actions: {
    setToCartQTD({ commit }, product) {
      commit('SET_PRODUCT_TO_CART_QTD', product)
    },
    addToCartQTD({ commit }, product) {
      commit('ADD_PRODUCT_TO_CART_QTD', product)
    },
    addToCart({ commit }, product) {
      commit('ADD_PRODUCT_TO_CART', product)
    },
    removeFromCart({ commit }, product) {
      commit('REMOVE_PRODUCT', product)
    },
    addPlusCart({ commit }, product) {
      commit('PLUS_PRODUCT_TO_CART', product)
    },
    addMinusCart({ commit }, product) {
      commit('MINUS_PRODUCT_TO_CART', product)
    },
    reOpen({ commit }, product) {
      commit('reOpen', product)
    },
    ping({ commit }, product) {
      commit('ping', product)
    },
    async loadLocationData({ commit }) {
      const response = await fetch('/data/locations.json');
      const data = await response.json();
      commit('updateLocations', data);
    },
  },
  getters: {
    products: (state) => state.products,
    carts: (state) => state.basket.product_items,
    cartsClear: (state) => state.basket.product_items,
    lojas: (state) => state.locations,
    allowDelivery: (state) => {
      return state.user.geolocation.entrega
    },
    cartsGroup: (state) => {
      let arr = state.basket.product_items
      let propriedade ='primary_category_root_id'
      return arr.reduce(function (acc:any, obj:any) {
        let key = obj[propriedade];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    },
    cartDelivery: (state) => {
      return state.delivery.values
    },
    cartTotal: (state) => {
      return state.basket.product_items.reduce((total, curItem) => {
        if(curItem.quantity > 1){

          return total + curItem.price_after_item_discount

        }else{
          return total + (curItem.price * curItem.quantity)
        }

      }, 0)
    },
    cartItemNumber: (state) => {
      return state.basket.product_items.reduce((count, curItem) => {
        return count + curItem.quantity
      }, 0)
    },
    productIncart: (state) => (id:any) => {
      return state.basket.product_items.find(todo => todo.id === id)
    }
  }
});
