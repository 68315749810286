import { createRouter, createWebHistory } from '@ionic/vue-router';
import { NavigationGuard, RouteRecordRaw } from 'vue-router';
import store from '../store'

const privateRoute: NavigationGuard = function(to, from, next) {
  if (store.state.user.isBemvindo) {
    next({ name: 'Bemvindo' });
  }else if (store.state.user.isGuest && to.meta.guest) {
    next();
  }else if (!store.state.user.isAuthenticated) {
    next({ name: 'Login' });
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Dashboard',
    meta: {guest : false},
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/bemvindo',
    name: 'Bemvindo',
    meta: {guest : true},
    component: () => import(/* webpackChunkName: "about" */ '../views/Bemvindo.vue')
  },
  {
    path: '/suporte',
    name: 'Suporte',
    meta: {guest : false},
    component: () => import(/* webpackChunkName: "about" */ '../views/Suporte.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/prelogin',
    name: 'PreLogin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PreLogin.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/cadastro',
    name: 'Cadastrar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Signup.vue'),
  },
  {
    path: '/perfil',
    name: 'Perfil',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Perfil.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/mercado',
    name: 'Mercado',
    meta: {guest : true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Mercado.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/pesquisar',
    name: 'Pesquisar',
    meta: {guest : true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Pesquisar.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/secoes',
    name: 'Secoes',
    meta: {guest : true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Secoes.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/categoria/:id',
    name: 'Categoria',
    meta: { guest : true, transition: 'slide-left' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Categoria.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/precategoria/:id',
    name: 'PreCategoria',
    meta: { guest : true, transition: 'slide-left' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/PreCategoria.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/subcategoria/:id',
    name: 'SubCategoria',
    meta: { guest : true, transition: 'slide-left' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/SubCategoria.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/produto/:id',
    name: 'Produto',
    meta: { guest : true, transition: 'slide-up' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Produto.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/carrinho',
    name: 'Carrinho',
    meta: {guest : true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Carrinho.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/checkout',
    name: 'Checkout',
    meta: {guest : true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Checkout.vue'),
    beforeEnter: privateRoute

  },
  {
    path: '/finalizacao',
    name: 'Finalizacao',
    meta: {guest : true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/CheckoutFinalizacao.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/confirmado',
    name: 'Confirmado',
    meta: {guest : true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Confirmado.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/pagamentopix',
    name: 'Pix',
    meta: {guest : true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/CheckoutPix.vue'),
    //beforeEnter: privateRoute
  },
  {
    path: '/churrasco',
    name: 'Churrasco',
    meta: {guest : false},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Churrasco.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/gondola',
    name: 'QRGondola',
    meta: {guest : false},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Gondola.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/recarga',
    name: 'Recarga',
    meta: {guest : false},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Recarga.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/contadigital',
    name: 'Conta Digital',
    meta: {guest : false},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Conta.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/premios',
    name: 'Premios',
    meta: {guest : false},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Premios.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/promocoes',
    name: 'Promocoes',
    meta: {guest : false},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Promocoes.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/tabloides',
    name: 'Tabloides',
    meta: {guest : false},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Tabloides.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/dasemana',
    name: 'DaSemana',
    meta: {guest : false},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OfertasSemana.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/ofertas/:id',
    name: 'Ofertas',
    meta: { guest : false },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Ofertas.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/pedidos',
    name: 'Pedidos',
    meta: {guest : false},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Pedidos.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/listas',
    name: 'Listas',
    meta: {guest : false},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Listas.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/sugestoes',
    name: 'Sugestoes',
    meta: {guest : false},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Sugestoes.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/privacidade',
    name: 'Privacidade',
    meta: {guest : false},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacidade.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/termos',
    name: 'Termos',
    meta: {guest : false},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacidade.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/cidades',
    name: 'Cidades',
    meta: {guest : true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Cidades.vue')
  },
  {
    path: '/lojas',
    name: 'Lojas',
    meta: {guest : true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Lojas.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/settings',
    name: 'Settings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/logout',
    name: 'Logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Logout.vue'),
    beforeEnter: privateRoute
  },
  {
    path: '/feedback',
    name: 'Feedback',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Feedback.vue'),
    beforeEnter: privateRoute
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

